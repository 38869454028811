import ModalCheckoutLink from "../ModalCheckoutLink/ModalCheckoutLink";


const GlobalModals = () => {

  return <>
    <ModalCheckoutLink/>
  </>;
}

export default GlobalModals;
