import { Cancel02Icon, Infinity01Icon } from "hugeicons-react";
import { useCallback } from "react";
import { css } from "styled-components";
import SwitchBtn from "../Switch/SwitchBtn";
import { Typography } from "../Typography";
import { SubcribtionSignal } from "./ModalCheckoutLink.signal";
import { ModalCheckoutContainer, ModalCheckoutContent, ProductContainer } from "./ModalCheckoutLink.style";

const ModalCheckoutLink = ({open, onClose}) => {
  const products = [
    {
      name: "Lite",
      sessions: "6",
      sessionsIcon: null,
      programs: <Cancel02Icon/>,
      price: "9"
    },
    {
      name: "Pro",
      sessions: "∞",
      sessionsIcon: <Infinity01Icon color={css`var(--accent-color)`}/>,
      programs: <Cancel02Icon/>,
      price: "19"
    },
    {
      name: "Pro +",
      sessions: "∞",
      sessionsIcon: <Infinity01Icon color={css`var(--accent-color)`}/>,
      programs: <Infinity01Icon color={css`var(--accent-color)`}/>,
      price: "49"
    }
  ];

  const onCloseCheckout = useCallback(() => {
    onClose();
    SubcribtionSignal.value = {
      ...SubcribtionSignal.value,
      isSubscribing: false
    };
  }, [onClose]);

  return <ModalCheckoutContainer
    closable
    visible={open}
    onClose={onCloseCheckout}
    children={
      <ModalCheckoutContent>
        {products.map((product) => (
          <ProductContainer key={product.name}>
            <Typography fz="26px" fw="600" lh="30px" >
              {product.name}
            </Typography>
            <Typography>
              {product.sessionsIcon || product.sessions} sessions per month
            </Typography>
            <Typography>
              {product.programs} Programs
            </Typography>
            <Typography fz="26px" fw="600" lh="30px" color={css`var(--accent-color)`} style={{justifyContent:'center'}}>
              {product.price}€ / month
            </Typography>
            <stripe-buy-button
              buy-button-id="buy_btn_1Qf2MTDfy7pLROjFZsy565HD"
              publishable-key="pk_test_51PzxAsDfy7pLROjFmBkeyY2KZs7bRUTmEnebRpvDyAHIRo9vRWocXxMmcCSIdZrpezx59F4whif7RYMijGT6LLmu00qekamQbM"
            >
            </stripe-buy-button>
          </ProductContainer>
        ))}
        <SwitchBtn leftIcon={<Typography lh='1.4' whiteSpace="nowrap"> Professional price </Typography>} width="40" size={20} style={{transition: '0.7s'}}/>
      </ModalCheckoutContent>
    }
  />;
};

export default ModalCheckoutLink;
