import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as Close } from '../../images/close-modal.svg';
import ModalWrapper from './styles';

class Modal extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    wrapClassName: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    noPadding: PropTypes.bool,
    visible: PropTypes.bool.isRequired,
    closeIcon: PropTypes.object,
    closable: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    customStyle: PropTypes.any
  };

  static defaultProps = {
    closable: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      body: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      visible: props.visible,
    };
  }

  componentDidMount() {
    if (this.state.visible) this.disableScroll();
  }

  componentDidUpdate(prevProps) {
    if (this.state.visible) this.disableScroll();
    else this.enableScroll();
  }

  componentWillUnmount() {
    this.enableScroll();
  }

  handleOnCancel = (e) => {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose(e);
    }
  };

  disableScroll() {
    if (!document) return;

    //scroll modal to top
    if (document.getElementsByClassName('ant-modal-body') && document.getElementsByClassName('ant-modal-body').length)
      document.getElementsByClassName('ant-modal-body')[0].scrollTop = 0;

    //disable scroll from parent window
    if (document.getElementsByTagName('HTML')) document.getElementsByTagName('HTML')[0].classList.add('html-no-scroll');
    else document.body.classList.add('position-fixed');
  }

  enableScroll() {
    // https://caniuse.com/#feat=classlist
    if (!document) return;

    if (document.getElementsByTagName('HTML')) document.getElementsByTagName('HTML')[0].classList.remove('html-no-scroll');
    else document.body.classList.remove('position-fixed');
  }

  render() {
    return (
      <ModalWrapper
        {...(this.props.className && { className: this.props.className })}
        noPadding={this.props.noPadding}
        customStyle={this.props.customStyle}
        destroyOnClose={true}
        visible={this.state.visible}
        title=""
        centered
        closable={this.props.closable}
        wrapClassName={this.props.wrapClassName}
        onCancel={this.handleOnCancel}
        closeIcon={this.props.closeIcon ? this.props.closeIcon : <Close />}
        footer={this.props.footer || null}
      >
        {this.props.children}
      </ModalWrapper>
    );
  }
}

export default Modal;
