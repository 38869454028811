import { SwitchBulbCenter, SwitchBulbIcon, SwitchContainer, SwitchInputChckBox, SwitchLabel } from "./SwitchBtn.style";



const SwitchBtn = ({width, onToggle, isEditProfile, leftIcon}) => {
  const height = width / 2;
  const bulbIconSize = height * 0.8;
  const bulbCenterSize = height * 0.33;
  const bulbCenterAfterSize = height * 0.12;
  const bulbCenterPosition = height * 0.093;
  const bulbPositionChecked = width * 0.55;
  const bulbShadowSpread = width * 0.22;
  return <SwitchContainer className="switch" hasLeftIcon={leftIcon != null} isEditProfile={isEditProfile} width={width} positionChecked={bulbPositionChecked} shadowSpread={bulbShadowSpread}>
  {leftIcon ? leftIcon : null }
  <SwitchInputChckBox type="checkbox" name="toggle" onClick={onToggle} />
    <SwitchLabel width={width} height={height} for="toggle">
      <SwitchBulbIcon size={bulbIconSize} position={bulbCenterPosition}>
        <SwitchBulbCenter size={bulbCenterSize} afterSize={bulbCenterAfterSize} />
      </SwitchBulbIcon>
    </SwitchLabel>
  </SwitchContainer>;
}


export default SwitchBtn;
