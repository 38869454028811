import { axiosBaseQuery } from '../lib/axios';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {  ApiAIRoutes, baseAiManagerApiUrl } from '../constants';

enum METHOD_TYPE {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export type Message = {
  role: 'system' | 'user' | 'assistant';
  content: string;
};
export const aiManagerApiSlice = createApi({
  reducerPath: 'aimanager',
  baseQuery: axiosBaseQuery({ baseUrl: baseAiManagerApiUrl }),
  tagTypes: [],
  endpoints: (builder) => ({
    getCsrf: builder.query<any, void>({
      query: () => ({
        url: `${ApiAIRoutes.CSRF}`,
        method: METHOD_TYPE.GET
      })
    }),
    chatCompletion: builder.mutation<
      any,
      {
        messages: Message[];
        model: string;
        session_id: string;
        user_language: string;
        temperature?: number;
        max_tokens?: number;
        top_p?: number;
        frequency_penalty?: number;
        presence_penalty?: number;
        is_summary?: boolean;
      }
    >({
      query: ({
        messages,
        model,
        session_id,
        user_language,
        temperature,
        max_tokens,
        top_p,
        frequency_penalty,
        presence_penalty,
        is_summary
      }) => ({
        url: `${ApiAIRoutes.COMPLETION}`,
        method: METHOD_TYPE.POST,
        data: {
          messages: messages,
          model: model ?? 'gpt-3.5-turbo',
          session_id: session_id,
          user_language: user_language,
          temperature: temperature,
          max_tokens: max_tokens,
          top_p: top_p,
          frequency_penalty: frequency_penalty,
          presence_penalty: presence_penalty,
          is_summary: is_summary
        }
      })
    })
  })
});

export const { useGetCsrfQuery, useChatCompletionMutation } = aiManagerApiSlice;
