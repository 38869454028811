import { Col } from 'antd';
import { AiBookIcon, CheckListIcon, Navigation03Icon } from 'hugeicons-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { css } from 'styled-components';
import { PageRoutes } from '../../../constants';
import { ReactComponent as Home } from '../../../images/home.svg';
import { ReactComponent as LuminousSvg } from '../../../images/luminous.svg';
import { ReactComponent as NewLogoSvg } from '../../../images/new-logo.svg';
import Button from '../../Button';
import ModalCheckoutLink from '../../ModalCheckoutLink/ModalCheckoutLink';
import { SubcribtionSignal } from '../../ModalCheckoutLink/ModalCheckoutLink.signal';
import { Typography } from '../../Typography';
import UserProfile from '../../UserProfile';
import { footerNavigationBarVisibleBreakpoint } from '../constants';
import { HeaderContentWrapper } from '../styled';
import { HeaderContent, HeaderContentNav, HeaderLinkContent, Logo, MainHeaderLinks, OpenSubscriptionBtn } from './styled';

export function DefaultHeader({
  loggedInUser,
  changeOnMobile,
  isMobile,
  headerNavButtonsWithHeaderSticky,
  isScrolling,
  showHeaderActions,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const [width, setWidth] = useState(0);
  const [openCheckoutModal, setOpenCheckoutModal] = useState(false);
  const changeWidth = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);
  const handleBtnSubscriptionClick = useCallback(() => {
    setOpenCheckoutModal(true);
    SubcribtionSignal.value = {
      ...SubcribtionSignal.value,
      isSubscribing: true
    }
  },[]);
  useEffect(() => {
    changeWidth();
    window.addEventListener('resize', changeWidth);
    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, [changeWidth]);
  return (
    <HeaderContentWrapper justifyContent="space-between" hide={isScrolling && headerNavButtonsWithHeaderSticky}>
      {width && (
        <>
          {changeOnMobile && width <= footerNavigationBarVisibleBreakpoint ? (
            <HeaderContent changeOnMobile>
              <HeaderLinkContent>
                <OpenSubscriptionBtn onClick={handleBtnSubscriptionClick} >
                  <Typography color={css`var(--accent-color)`} fw='600' lh="14px" > { t('subscribe_button')}</Typography>
                </OpenSubscriptionBtn>
              <Link to={PageRoutes.AI_SESSIONS+ `/${process.env.NODE_ENV === 'development' ? '17' : '71'}`} className='ai-assistant-link'>
                <AiBookIcon />
              </Link>
              <Link to={PageRoutes.PROFILE}>
                <UserProfile changeOnMobile size="small" pictureUrl={loggedInUser.picture?.formats.small.url}></UserProfile>
              </Link>
              </HeaderLinkContent>
              <Link to={loggedInUser.isAuthenticated ? PageRoutes.HOME : PageRoutes.LANDING}>
                <Logo changeOnMobile>
                  <div style={{ display: 'flex' }}>
                    <NewLogoSvg style={{ marginRight: '10px', width: '40px' }} />
                    <LuminousSvg style={{ marginTop: '8px' }} />
                  </div>
                </Logo>
              </Link>
            </HeaderContent>
          ) : (
            <>
              <Col md={10} lg={10} noPadding>
                <HeaderContent>
                  <Link to={loggedInUser.isAuthenticated ? PageRoutes.HOME : PageRoutes.LANDING}>
                    <Logo changeOnMobile>
                      <div style={{ display: 'flex' }}>
                        <NewLogoSvg style={{ marginRight: '10px', width: '57px' }} />
                        <LuminousSvg style={{ marginTop: '15px' }} />
                      </div>
                    </Logo>
                  </Link>
                  {loggedInUser.isAuthenticated && (
                    <>
                      <Link to={PageRoutes.HOME}>
                        <MainHeaderLinks className={location.pathname === PageRoutes.HOME ? 'active' : ''}>
                          <Home className="home" />
                          {t('menu_dashboard')}
                        </MainHeaderLinks>
                      </Link>
                      {/* <Link to={PageRoutes.SESSIONS_LIBRARY}>
                        <MainHeaderLinks className={location.pathname === PageRoutes.SESSIONS_LIBRARY ? 'active' : ''}>
                          <PlayIcon className="start" />
                          {t('profile_sessions')}
                        </MainHeaderLinks>
                      </Link> */}

                      <Link to={PageRoutes.PROGRAMS_LIBRARY}>
                        <MainHeaderLinks className={location.pathname === PageRoutes.PROGRAMS_LIBRARY ? 'active' : ''}>
                          <Navigation03Icon className="start" />
                          {t('programs')}
                        </MainHeaderLinks>
                      </Link>
                      <Link to={ PageRoutes.TO_DOS_LIBRARY}>
                        <MainHeaderLinks className={location.pathname === PageRoutes.TO_DOS_LIBRARY ? 'active' : ''}>
                          <CheckListIcon className="start" />
                          {t('profile_to_dos')}
                        </MainHeaderLinks>
                      </Link>
                    </>
                  )}
                </HeaderContent>
              </Col>
              <Col md={2} lg={2} noPadding>
                <HeaderContentNav hiddenLogo={isScrolling && headerNavButtonsWithHeaderSticky}>
                  {isMobile &&
                    isScrolling &&
                    headerNavButtonsWithHeaderSticky &&
                    headerNavButtonsWithHeaderSticky.map((headerNavButton) => headerNavButton)}
                  {!isMobile &&
                    headerNavButtonsWithHeaderSticky &&
                    headerNavButtonsWithHeaderSticky.map((headerNavButton) => headerNavButton)}
                  {loggedInUser.isAuthenticated ? (
                    <>
                        {/* <OpenSubscriptionBtn onClick={handleBtnSubscriptionClick} >
                          <Typography color={css`var(--accent-color)`} fw='600' lh="14px" > { t('subscribe_button')}</Typography>
                        </OpenSubscriptionBtn> */}

                      <Link to={PageRoutes.AI_SESSIONS+ `/${process.env.NODE_ENV === 'development' ? '17' : '71'}`} className='ai-assistant-link'>
                        <AiBookIcon />
                      </Link>
                      <Link to={PageRoutes.PROFILE}>
                        <UserProfile size="small" pictureUrl={loggedInUser.picture?.url}></UserProfile>
                      </Link>
                    </>
                  ) : (
                    showHeaderActions && (
                      <Button styles="secondary" size="large" href={PageRoutes.LOGIN}>
                        {t('login_button')}
                      </Button>
                    )
                  )}
                </HeaderContentNav>
              </Col>
            </>
          )}
        </>
      )}
      <ModalCheckoutLink open={openCheckoutModal } onClose={ () => setOpenCheckoutModal(false)} />
    </HeaderContentWrapper>
  );
}
