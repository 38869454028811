import styled, { css } from "styled-components";
const UNCHECKED_COLOR = '#A29BA4';
export const SwitchBulbCenter = styled.span`
  ${css`
      position: absolute;
      display: block;
      height: ${props => props.size ? props.size :  36}px;
      width:  ${props => props.size ? props.size :  36}px;
      background-color: ${UNCHECKED_COLOR};
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transition: 0.7s;
      transform: translate(-50%, -50%);
      box-shadow: inset 0 0 0 4px ${UNCHECKED_COLOR};
      &:after{
          content: "";
          display: block;
          height:  ${props => props.afterSize ? props.afterSize :  14}px;
          width:  ${props => props.afterSize ? props.afterSize :  14}px;
          background-color: ${UNCHECKED_COLOR};
          border-radius: 50%;
          position: absolute;
          transition: 0.7s;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0 0 2px 4px ${UNCHECKED_COLOR};
      }
    `}
  `;
export const SwitchBulbIcon = styled.i`
  ${css`
          height:  ${props => props.size ? props.size :  90}px;
          width:  ${props => props.size ? props.size :  90}px;
          background-color: ${UNCHECKED_COLOR};
          border-radius: 50%;
          position: relative;
          top: ${(props) => props.position ? props.position : 10}px;
          left: ${(props) => props.position ? props.position : 10}px;
          display: block;
          transition: 0.7s;
          box-shadow:
          inset 0 0 1px 3px ${UNCHECKED_COLOR},
          inset 0 0 6px 8px ${UNCHECKED_COLOR},
          0 20px 30px -10px rgba(0, 0, 0, 0.4);
    `}
  `

export const SwitchInputChckBox = styled.input`
  ${css`
      height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: 100;
        cursor: pointer;
    `}
  `;
export const SwitchLabel = styled.label`
  ${css`
      height:  ${(props) => props.height ? props.height :  110}px;
      width:  ${(props) => props.width ? props.width :  220}px;
      background-color: white;
      border-radius: 100px;
      display: block;
      box-shadow:
      inset 0 0 20px rgba(0, 0, 0, 0.2),
      inset 0 0 5px -2px rgba(0, 0, 0, 0.4);
    `}
  `;
export const SwitchContainer = styled.div`
  ${css`
    position: relative;
    width: fit-content;
    margin: 0 auto;

    ${(props) => props.hasLeftIcon && css`
        display: grid;
        grid-template-columns: 0fr 1fr;
        gap: 4px;
        place-content: center;
      `}
    ${SwitchInputChckBox}:checked ~ ${SwitchLabel}{
        ${SwitchBulbIcon}{
        left: ${props => props.positionChecked ? props.positionChecked : 120}px;
        background-color: #fc6453c7;
        box-shadow: inset 0 0 1px 3px #fc6453a1, inset 0 0 6px 8px #fc64538f, 0 20px 30px -10px #fc645363, 0 0 30px ${props => props.shadowSpread ? props.shadowSpread : 50}px #fc645321;
        & > ${SwitchBulbCenter}{
            background-color: #fc645354;
            box-shadow: inset 0 0 0 4px #fc645324, 0 0 12px 10px #fc645378, 0 0 20px 14px #f6bfb94a;
            &:after{
                background-color: #fc64532b;
                box-shadow: 0 0 2px 4px #fd774399;
            }
        }
        }

    }

    `}
  `;
