import { Modal } from 'antd';
import styled, { css } from 'styled-components';
import media, { mediaMax } from '../../styles/mixins/media';

export default styled(Modal)`
    ${css`
        ${media.md`
          width: ${(props) => props.theme.utils.rem(props.theme.sections.mdModalWidth)} !important;
        `};

        &.auto-width-modal {
          width: auto !important;
        }

        .ant-modal-content {
          background-color: #fdfcff !important;
          overflow: hidden;
          border-radius: 8px;
        }
        &.ratingProgram {
          .ant-modal-body {
            padding: 0 11px;

            ${media.md`
              padding: 0 80px;
            `}
          }
        }

        &.opinion-modal {
          .ant-modal-content {
            background-color: #f8f4ee !important;
            border-radius: 8px;
          }
          ${mediaMax.sm`
            width: 100% !important;
            max-width: 100%;
            margin-bottom: 0;
            padding-bottom: 0;
            &:before{
              height: 100%;
              vertical-align: bottom;
            }
            .ant-modal-content {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          `}
          ${media.md`
            width: 760px;
            &.ratingProgram{
              height: 720px;
              width: 940px !important;
              margin-bottom: 76px;
              .ant-modal-content {height: 100%;}
              .ant-modal-body {height: 100%;}
            }
          `}
          button {
            .ant-modal-close-x {
              svg {
                width: 32px;
                height: 32px;
              }
            }
          }
        }
        &.modal-padding {
          .ant-modal-body {
            ${media.md`
              padding: 0 80px 60px;
            `}
          }
          button {
            .ant-modal-close-x {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            svg {
              width: 32px;
              height: 32px;
            }
          }
        }
        &.white-modal {
          .ant-modal-content {
            background-color: #fff !important;
            border-radius: 8px;
          }
        }

        &.full-mobile-modal {
          ${mediaMax.sm`
            width: 100% !important;
            max-width: 100%;
            min-height: 100vh;
            margin: 0;
            padding: 0;

            .ant-modal-content {
              border-radius: 0;
              min-height: 100vh;
            }
          `}
        }
        &.needs-filter-modal {
          max-height: 100%;
          overflow-y: scroll;
          .ant-modal-body {
            background-color: #f8f4ee !important;
            max-height: calc(100vh - 150px);
            overflow-y: scroll;
          }
        }
        &.modalResponsive {
          margin: 0 0;
          .ant-modal-body {
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 2000px;
            height: 100%;
            position: initial;
            background-color: #fcfafb;

            &::-webkit-scrollbar {
              display: none;
            }

            ${mediaMax.md`
              border-radius: 8px 8px 0 0;
            `}
          }

          .ant-modal-close {
            svg {
              path:first-child {
                fill: none;
              }
              path:last-child {
                stroke-width: 2;
              }
            }
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }
        .ant-modal-close > span > svg > path:nth-child(1) {
          fill: none;
        }

        ${mediaMax.sm`
            &.closeOnTop .ant-modal-close {
              top: -63px;
              margin-bottom: 0;
              margin-right: 8px;

              svg {
                height: 40px;
              }
            }
          `}
        ${media.sm`
          &.modalInfo {
            .ant-modal-close {
             /*  margin: 2rem 1rem 1rem 1rem; */
             margin: 7px;

              svg {
                width: 36px;
              }
            }
          }
        `};

        &.modalInfo-body {
          .ant-modal-body {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .ant-modal-body {
          padding-top: 0;
          overflow: hidden;
          position: relative;

          ${(props) =>
            props.noPadding &&
            `
            padding: 0 !important;
          `}
        }
        .ant-modal-close {
          margin: ${(props) => props.theme.layout.tiny};
        }

        &.skill-modal {
          ${media.md`
            width: 350px !important;
          `};

          .ant-modal-body {
            padding: 0;
          }
        }

        &.left-close-button {
          .ant-modal-close {
            left: 0;
            right: unset;

            svg {
              border-radius: 50%;
              box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
                0px 0px 1px rgba(0, 0, 0, 0.04);

              ${media.md`
                width: 48px;
                height: 48px;
              `}
            }
          }
        }
        &.program-completed-modal {
          margin-top: 56px;
          .ant-modal-content {
            background-color: #fcfafb !important;
          }
        }
        &.loading-modal {
          inset: 0 !important;
          width: 100% !important;
          height: 100%;
          max-height: 100%;
          .ant-modal-content {
            background: transparent !important;
          }
          .ant-modal-body {
            height: 100vh;
          }
        }
        &.feedback-modal-session {
          background: transparent;
          .ant-modal-close {
            top: -15px;
            right: -20px;
          }
          .ant-modal-content {
            background: transparent !important;
            box-shadow: none !important;
          }
        }
        &.ai-end-modal-session {
            height: 500px;
            @media screen and (max-width: 834px){
                height: 450px;

            }
            .ant-modal-content,.ant-modal-body,.ant-carousel,.slick-slider,.slick-list,.slick-track{
                height: inherit;

            }
            &:has(.section-summary){
              .ant-modal-body{
                overflow-y: scroll;
              }
              .skip-summary{
                bottom: 12px;

              width: 100%;
              left: 0;
              position: sticky;
              z-index: 100001;
              @media screen and (max-width: 834px){
                bottom: 12px;

              }
              @media screen and (max-width: 501px){
                bottom: 12px;

              }
              button{
              margin: 0 auto;
              box-shadow:0 5px 14px 5px rgb(255 255 255);

              }

 
              }
            }

        .ant-carousel{
              pointer-events: none;
        .slick-slider{
        .slick-prev{
      left: 8px;
      @media screen and (max-width: 834px){
      left: -2px;
      }
       }

       .slick-next{
       right: 8px;
       @media screen and (max-width: 834px){
       right: -2px;
       }
       }
       .slick-prev, .slick-next{
       width: 32px;
           height: 32px;
       top:45%;
       color:    rgb(254 148 134 / 100%)!important;
      z-index: 1000;
       }
        }
         .slick-dots {li{
          background: rgb(254 148 134 / 40%);

         }
         .slick-active button{
         background: rgb(254 148 134 / 100%)!important;

         }
         }

         .slick-slide{
           div{


         .section-feedback{
             place-content: center;

        > div:first-child{
            display: flex;
            flex-direction: column;
            gap: 32px;
            align-items: center;
            width:100%;

        }
         div{
         align-items:center;
         justify-content:center;
         display:flex;

         div:first-child{
         flex: 1;
         margin: 0;
         padding: 0;
         width: 100%;
         align-self: center;
         .yes, .no{
         box-shadow:-4px -4px 15px 0 rgba(255,255,255,0.3), 4px 4px 15px 0 rgba(110,110,110,0.2)
         }
         &::before, &::after{
         background: none;
         background-color:#fdfcff;
         backdrop-filter: blur(0px);
         -webkit-backdrop-filter: blur(0px);
         }
         }
         }

             svg {
             flex: 0;
             cursor: pointer;
             min-width: 32px;
             }
             .quit-button-calendar{
                 font-size: 1rem;
             }

             .quit-session{
                 z-index: 9;
                 top: 124px;
                 position: relative;
                 font-size: 1rem;
                 @media screen and (max-width: 834px){
                     top: 76px;

                 }
             }
           }
           .section-save{

           }
           }
           }
           }
           }
           &.pdf-modal{
               width: 75vw!important;
               @media screen and (max-width: 834px){
                   width: 100%;
               }
               .ant-modal-content{
                   width: 75vw;

               }
               .ant-modal-close{
                       margin: 0.2rem 0;
               }
           }
           &.video-modal{
               height: fit-content;
               width: 75vw!important;
               @media screen and (max-width: 834px){
                   width: 100%!important;
               }
               .ant-modal-content{

               width: 75vw;
               @media screen and (max-width: 834px){
                   width: 100%;
               }
               }
               iframe{
                   z-index: 1;
               }
           }
      `}

  ${(props) => props.customStyle}
`;
