import styled from 'styled-components';
import Modal from '../Modal';

export const ModalCheckoutContainer = styled(Modal)`
  width: fit-content !important;
  .ant-modal-centered::before {
    height: 45%;
  }

  .ant-modal-content {
    background: #f9f7f3d6 !important;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
`;
export const ModalCheckoutContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  @media screen and (min-width: 834px) {
    .switch {
      grid-row: 2;
      grid-column: 2;
      align-self: end;
      left: 16px;
    }
  }

  @media screen and (max-width: 834px) {
    grid-template-columns: 1fr;
  }
  padding: 24px;
`;

export const ProductContainer = styled.div`
  display: grid ;
  div {
    align-items: center;
    display: flex;
    gap: 8px;
  }
`;
